import Modules from "@/store/enums/Modules";
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import NewTransfer from "@/models/weewhouse/NewTransfer";
import NewTransferState from "../../models/NewTransferState";
import WarehouseTransfersService from "@/core/services/werehouse/WarehouseTransfersService";
import { ActionsForm } from "@/store/commons/ActionsForm";
import { MutationsForm } from "@/store/commons/MutationsForm";
import { realoadTable } from "@/store/functions/GenericTable";
import OptionSelect from "@/models/shared/OptionSelect";
import WarehouseComboService from "@/core/services/werehouse/WarehouseComboService";
import MovementTypeComboService from "@/core/services/movementTypes/MovementTypeComboService";
import { MovementType } from "@/core/enums/movement-types";
import { FormTypes } from "@/core/enums/form-types";

enum Mutations { 
    SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_INFO_TRANSFER = "SET_INFO_TRANSFER"
}
enum Actions { 
    SEARCH_INFO_TRANSFER = 'SEARCH_INFO_TRANSFER'
}

@Module({dynamic: true, store, namespaced: true, name: Modules.NewTransfer})
class NewTransferModule extends VuexModule implements NewTransferState {
    idSelected = '';
    Transfer = taxesDefaultValue;
    loading = false;
    selects = {
        originWarehouseOptions: [] as OptionSelect[] 
        , destionationWarehouseOptions: [] as OptionSelect[]
        , movementOptions: [] as OptionSelect[]
    };
    formType = FormTypes.Create;

    get getTranferInfo(): NewTransfer {
        return this.Transfer;
    }
    /**
     * Indica si existe un identificador
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }
    get getMovementOptions() : OptionSelect[] {
        return this.selects.movementOptions;
    }

    @Mutation
    [Mutations.SET_INFO_TRANSFER](value: NewTransfer){
        this.Transfer = value;
        this.idSelected = value.id;

    }
    @Mutation
    [MutationsForm.RESET_ID_SELECT](){
        this.idSelected = '';
    }
    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [MutationsForm.SET_FORM_TYPE](newFormType: FormTypes){
        this.formType = newFormType;
    }
    
    @Mutation
    [MutationsForm.RESET_VALUES_FORM](){
        this.Transfer = taxesDefaultValue;
        this.idSelected = '';
    }
    
    @Action({ commit: Mutations.SET_INFO_TRANSFER })
    async [Actions.SEARCH_INFO_TRANSFER](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getInfo(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }
      @Action
    async [ActionsForm.SAVE_FORM](form: NewTransfer){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.save(form)
            .then((resp) => {
                let isSuccess = false;
                if(resp.data){
                    realoadTable(this.context, Modules.TableTransfersModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                    isSuccess = true;
                } 
                return isSuccess;
            })
            .finally(() => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            })
    }
    @Action
    async [ActionsForm.UPDATE_FORM](newData: NewTransfer){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.update(this.idSelected, newData)
            .then((resp) => {
                let isSuccess = false;
                if(resp.data){
                    realoadTable(this.context, Modules.TableTransfersModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                    isSuccess = true;
                } 
                return isSuccess;
            })
            .finally( () => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            });
    }
    @MutationAction
    async getComboOriginWarehouse(filterName: string) {
         const comboOptions = await warehouseService.searchCombo(filterName);
         const selects = { ...this.selects }
        //if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, originWarehouseOptions: comboOptions.data ?? [] } }
    }
     @MutationAction
     async getComboDestionationWarehouse(filterName: string) {
         const comboOptions = await warehouseService.searchCombo(filterName);
         const selects = { ...this.selects }
        //if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, destionationWarehouseOptions: comboOptions.data ?? [] } }
    }
    @MutationAction
     async getComboMovement(filterName: string) {
        const comboOptions = await comboService.searchCombo(MovementType.Transfer, filterName);
         const selects = { ...this.selects }
        //if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, movementOptions: comboOptions.data ?? [] } }
    }
}

const service =  new WarehouseTransfersService();
const warehouseService = new WarehouseComboService();
const comboService = new MovementTypeComboService();

const taxesDefaultValue = {
    id: ""
    , destinationWarehouseId: ''
    , movementTypeId: ''
    , originWarehouseId: ''
    , observations: ''
} as NewTransfer;

export default getModule(NewTransferModule);
