
import { Form } from "vee-validate";
import { defineComponent, computed, watch, ref, onMounted } from "vue";
import * as Validations from "yup";
import NewTransfer from "@/models/weewhouse/NewTransfer";
import TextArea from "@/components/forms/TextArea.vue"
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import moduleForm from "@/store/modules/werehouse/modules/transfers-form"
import { hideModalById } from "@/core/helpers/dom";
import { debounce } from "lodash";
import { FormTypes } from "@/core/enums/form-types";

export default defineComponent({
    components: {
        Form
        , TextArea
        , SelectFilterRemote
    },
    setup() {
        const formTransfer = ref();

        const validationSchema = computed(() => Validations.object().shape({
            originWarehouseId: Validations.string().default('').required()
            , destinationWarehouseId: Validations.string().default('').required()
            , movementTypeId: Validations.string().default('').required()
            , observations: Validations.string().default('').optional()
        }));

        const saveTransfer = (form: NewTransfer,  { resetForm }): void => {
            //save transfer
            if (formType.value == FormTypes.Update) { 
                moduleForm.UPDATE_FORM(form)
                    .then((isSuccess) => { 
                        if (isSuccess) { 
                            hideModalById('#new-transfer');
                            resetForm();
                        } 
                    })
            } else {
                moduleForm.SAVE_FORM(form)
                    .then((isSuccess) => { 
                        if (isSuccess) {
                            hideModalById('#new-transfer');
                            resetForm();
                        }
                    });
            }
        }
        const searchOriginWarehouse = debounce((filterName: string) => {
            moduleForm.getComboOriginWarehouse(filterName);
        }, 550, { leading: false, trailing: true })

        const searchDestinationWarehouse = debounce((filterName: string) => {
            moduleForm.getComboDestionationWarehouse(filterName);
        }, 550, { leading: false, trailing: true })

        const searchMovementType = debounce((filterName: string) => {
            //if (filterName.length > 3) { 
                moduleForm.getComboMovement(filterName);
            //}
        }, 550, { leading: false, trailing: true })

        const originWarehouseOptions = computed(() => moduleForm.selects.originWarehouseOptions);
        const destionationWarehouseOptions = computed(() => moduleForm.selects.destionationWarehouseOptions);
        const movementOptions = computed(() => moduleForm.getMovementOptions);
        const formType = computed(() => moduleForm.formType);

        watch(() => moduleForm.getTranferInfo, (currentValue) => { 
            if (currentValue && currentValue.id != '') {
                formTransfer.value.setValues({
                    ...(currentValue as NewTransfer)
                });
            } else {
                formTransfer.value.resetForm();
            }
        })

        onMounted(() => { 
            //por mientras hacemos estas llamadas
            searchMovementType('');
            searchDestinationWarehouse('');
            searchOriginWarehouse('');
        })

        return {
            validationSchema
            , saveTransfer
            , originWarehouseOptions
            , destionationWarehouseOptions
            , movementOptions
            , formTransfer
            , formType
            , searchOriginWarehouse
            , searchDestinationWarehouse
            , searchMovementType
            , FormTypes
        }
    }
})
